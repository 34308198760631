<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockMaasTextImage = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "content";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockMaasTextImage;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slot: any = getSlotContent("quote");

const { getConfigValue } = useCmsElementConfig(slot);
const text: string = getConfigValue("text");
const author: string = getConfigValue("author");
</script>

<template>
  <div class="s-gutter">
    <div class="c-width-narrow bg-maas-background-grey-light">
      <CmsElementQuote :text="text" :author="author" />
    </div>
  </div>
</template>
